import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"



const Ad = ({data}) => {
  const image = getImage(data.contentfulAd.img.gatsbyImageData)

    return (
        <Layout>
            <div className="flex justify-center items-center flex-col">
                <div className="p-6 m-3 border border-orange w-72 text-center">{data.contentfulAd.title}</div>

                <GatsbyImage image={image} alt="" />


                {/* <img src={data.contentfulAd.img.fluid.srcWebp} alt="" /> */}


            </div>
        </Layout>
    )
}

export default Ad




export const query = graphql`
query ad($slug: String!) {
    contentfulAd(title: {eq: $slug}) {
      title
      id
      img {
        gatsbyImageData(formats: AUTO, placeholder: BLURRED, width: 500)
        fluid {
          srcWebp
        }
      }
    }
  }
`